import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { graphql } from "gatsby";
import PageBanner from "../components/elements/PageBanner";
import Pagination from "../components/common/Pagination";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";
import components from "../components/slices/index";
import { constructActiveDoc } from "../utilities/helpers";

import "../styles/pages/blog_page.scss";
import BlogCard from "../components/elements/BlogCard";

const Blog = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  // console.log(data);

  return (
    <div className={data.page.type}>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
          icon={page.data.banner_icon}
        />
        <Container fluid className="blog-page section">
          <Container className="max-container">
            <Row className="gy-4 mb-5">
              {data.posts.edges.map((post, index) => (
                <Col sm={6} lg={4} key={index}>
                  <BlogCard info={post.node} />
                </Col>
              ))}
            </Row>
            <div className="d-none">
              <Pagination
                pageInfo={data.posts.pageInfo}
                // base={pageContext.base}
              />
            </div>

            <SliceZone components={components} slices={page.data.body} />
          </Container>
        </Container>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query blogQuery($lang: String, $limit: Int, $skip: Int, $blog: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicBlog(lang: { eq: $lang }) {
      ...prismicBlogFragment
    }
    posts: allPrismicBlogPost(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $lang }, data: { blog: { uid: { eq: $blog } } } }
      sort: { fields: data___publication_date, order: DESC }
    ) {
      edges {
        node {
          url
          data {
            blog_image {
              alt
              gatsbyImageData(placeholder: BLURRED)
              url
            }
            image_mobile {
              alt
              gatsbyImageData(placeholder: BLURRED)
              url
            }
            tag
            publication_date(formatString: "Do MMMM YYYY")
            text {
              richText
            }
            title {
              text
            }
          }
          uid
          url
          lang
          type
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;

export default Blog;
